<template>
  <Card class="d-flex flex-column" :to="to">
    <AdaptiveImg :src="event.images[0]" :class="{ 'img-gray': isGray }">
      <div class="pa-4 d-flex" style="line-height: 1">
        <div>
          <Tag v-if="event.status === EventStatus.PASSED" class="mr-2" text-color="primary" x-small>
            {{ $t('Завершено') }}
          </Tag>
          <Tag
            v-if="event.status === EventStatus.CANCELED"
            class="mr-2"
            text-color="primary"
            x-small
          >
            {{ $t('Отменено') }}
          </Tag>
          <Tag v-if="event.streamUrl" class="mr-2" text-color="primary" x-small>
            {{ $t('Есть запись') }}
          </Tag>
        </div>
        <v-spacer />
        <template v-if="event.registrationType === 'internal' && tariff">
          <Tag class="ml-2" text-color="primary" x-small>
            {{
              event.prices && event.prices[tariff]
                ? $n(event.prices[tariff], 'currency')
                : $t('Бесплатно')
            }}
          </Tag>
        </template>
        <Tag v-if="ticketsLeft === 0" class="ml-2" text-color="primary" x-small>
          {{ $t('Мест нет') }}
        </Tag>
      </div>
      <v-spacer />
    </AdaptiveImg>

    <div class="py-4 px-6 flex-grow-1 d-flex flex-column">
      <v-card-title class="pa-0 flex-grow-1 align-content-start text-body-1 font-weight-medium">
        {{ event.name }}
      </v-card-title>

      <div class="mt-2 d-flex align-center justify-space-between">
        <div class="text-body-1" :class="[isGray ? 't-grey--text' : 'primary--text']">
          {{ event.startAt | date('dateLong') }}
        </div>
        <div class="d-flex align-start">
          <EventAction
            :value="liked"
            :count="event.likeCount"
            class="ml-2"
            icon-on="mdi-heart"
            icon-off="mdi-heart-outline"
            small
            @click="$emit('like', event.id)"
          />
          <EventAction
            :value="subscribed"
            :count="showAdminInfo ? event.userCount : undefined"
            :disabled="hasActiveTicket"
            class="ml-2"
            icon-on="mdi-bookmark"
            icon-off="mdi-bookmark-outline"
            small
            @click="$emit('subscribe', event.id)"
          />
          <EventAction
            v-if="ticketsInfo"
            :count="ticketsInfo"
            class="ml-2"
            icon-on="mdi-ticket-account"
            icon-off="mdi-ticket-outline"
            small
          />
        </div>
      </div>

      <div class="mt-2 d-flex align-center">
        <div class="text-body-1" :class="[isGray ? 't-grey--text' : 'primary--text']">
          {{ event.startAt | time }} — {{ event.endAt | timeTo }}
        </div>
        <Tag
          v-if="event.status === EventStatus.CURRENT"
          class="ml-2"
          color="primary"
          text-color="white"
          x-small
        >
          {{ $t('Сейчас') }}
        </Tag>
      </div>

      <div v-if="event.category" class="mt-2 text-body-2 t-grey--text">
        {{ event.category.name }}
      </div>
    </div>

    <div class="px-6">
      <v-divider class="t-back-light" />
    </div>

    <div class="py-4 px-6">
      <v-sheet class="mr-n1" min-height="16">
        <Tag
          v-if="event.fixedOnTopPosition"
          class="mr-1"
          color="primary"
          text-color="white"
          x-small
        >
          {{ $t('Важно') }}
        </Tag>
        <Tag
          v-for="tag in event.tags"
          :key="tag"
          class="mr-1"
          x-small
          @click.prevent.stop="$emit('click:tag', tag)"
        >
          {{ tag }}
        </Tag>
      </v-sheet>
    </div>

    <slot name="menu"></slot>
  </Card>
</template>

<script>
import EventAction from '@/components/event/EventAction';
import Card from '@/components/ui/Card';
import Tag from '@/components/ui/Tag';
import AdaptiveImg from '@/components/ui/AdaptiveImg';
import { EventStatus, EventTicketStatus } from '@/constants/event';

export default {
  name: 'EventCard',

  components: { AdaptiveImg, Tag, Card, EventAction },

  props: {
    event: Object,
    liked: Boolean,
    showAdminInfo: {
      type: Boolean,
      default: false,
    },
    subscribed: Boolean,
    tariff: String,
    ticket: Object,
    to: Object,
  },

  computed: {
    isGray() {
      return [EventStatus.PASSED, EventStatus.CANCELED].includes(this.event.status);
    },

    hasActiveTicket() {
      return this.ticket && this.ticket.status !== EventTicketStatus.CANCELED;
    },

    ticketsLeft() {
      return this.event.participantsLimit
        ? this.event.participantsLimit - this.event.ticketCount
        : undefined;
    },

    ticketsInfo() {
      if (!this.event.participantsLimit) {
        return null;
      }

      if (this.showAdminInfo) {
        return `${this.event.ticketCount} / ${this.event.participantsLimit}`;
      }

      return this.ticketsLeft > 0 ? this.ticketsLeft : null;
    },
  },

  beforeCreate() {
    this.EventStatus = EventStatus;
  },
};
</script>

<style scoped>
.img-gray::v-deep .v-image__image {
  filter: grayscale(100%);
}
</style>
