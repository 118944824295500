import eventApi from '@/api/eventApi';
import { makeLoadableList } from '@/store/factory/loadableList';

export default makeLoadableList(
  {
    name: 'admin',
    dataModel: 'db/adminEvents',
    // limit: 12,
    params: {
      sort: 'fixed_on_top_position,start_at',
    },
  },
  {
    state: {
      draft: null,
      stat: [],
    },
    getters: {
      draft: state => state.draft,
      stat: state => state.stat,
    },
    actions: {
      saveDraft({ commit }, draft) {
        commit('SET_DATA', { draft });
      },
      deleteDraft({ commit }) {
        commit('SET_DATA', { draft: null });
      },
      async fetchStat({ commit }, draft) {
        await eventApi.admin.fetchStat().then(res => commit('SET_DATA', { stat: res.data }));
        commit('SET_DATA', { draft });
      },
    },
    mutations: {
      SET_DATA(state, obj) {
        Object.keys(obj).forEach(key => (state[key] = obj[key]));
      },
    },
  }
);
